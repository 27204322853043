@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

:root {
    --feedback-negative-800: #BD0300;
    --feedback-positive-800: #056753;
}

body {
    margin: 0;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    overflow-x: hidden;
    scroll-padding-top: 80px;
    scroll-padding-bottom: 80px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-before-link {
    padding-right: 10px;
}

.address-search-list {
    width: auto !important;
    font-family: Karla;
    padding-left: 8px !important;
    padding-right: 32px !important;
    font-weight: 400;
    min-height: 150px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 1300;
    position: fixed;
}

.address-search-list-item {
    font-family: Karla;
    padding: 4px 0px !important;
    font-weight: 400;
}

.healthkey-container {
    background: #f4faff;
    height: 100%;
    min-height: 100vh;
    max-width: 1440px;
    margin: 0 auto;
}

.healthkey-checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.healthkey-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.healthkey-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
}

.healthkey_drag_drop {
    height: 150px !important;
    border-radius: 24px !important;
}

.healthkey_drag_drop:hover {
    background: #E7F1FB;
}

[role="button"]:hover,
[role="button"]:focus,
[role="button"]:active {
    border-color: #005ba1;
}

.skip-link {
    position: absolute;
    overflow: hidden;
    top: auto;
    left: -10000px;
    width: 1px;
    height: 1px;
}

.skip-link:focus {
    position: static;
    width: auto;
    height: auto;
    color: #005ba1;
    font-size: 1rem;
    font-family: Karla;
    font-weight: 400;
    line-height: 150%;
}

.quill {
    width: 100%
}

.ql-toolbar.ql-snow {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/* ql-editor class is used when text is rendered in a non-editable format for the end user so I specify.ql-snow also -- LH, 2024-04-30 */
.ql-snow .ql-editor {
    min-height: 200px;
}

.ql-container {
    padding: 4px 1px;
    overflow-y: auto;
    font-family: "Karla",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "sans-serif" !important;
    font-size: 16px !important;
    word-break: break-word;
}

.ql-container.ql-snow {
    border: 1px solid #e9ecef !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.ql-container.ql-bubble {
    padding: 0px;
}

.ql-container.ql-bubble .ql-editor {
    padding: 0px;
}

.ql-container.ql-bubble .ql-editor a {
    color: #105A9A !important;
}

.ql-container.ql-bubble .ql-editor a:hover {
    color: #00294C
}

.ql-container.ql-bubble .ql-editor p {
    color: var(--Core-Text, #2C2C2C);
    /* 🖥️ Desktop (MD - 3XL)/Body/Body */
    font-family: Karla;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02rem;
}

.ql-container.ql-bubble .ql-editor h1 {
    color: #000;
    /* 🖥️ Desktop (MD - 3XL)/Heading/XL */
    font-family: Karla;
    font-size: 2.0625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 39.6px */
    letter-spacing: -1.32px;
}

.ql-container.ql-bubble .ql-editor h2 {
    color: #000;
    /* 🖥️ Desktop (MD - 3XL)/Heading/L */
    font-family: Karla;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 36.4px */
    letter-spacing: -0.84px;
}

.ql-container.ql-bubble .ql-editor h3 {
    color: #000;
    /* 🖥️ Desktop (MD - 3XL)/Heading/M */
    font-family: Karla;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 30.8px */
    letter-spacing: -0.66px;
}


.service-description .ql-container.ql-snow .ql-editor p {
    color: var(--Core-Text, #2C2C2C);
    /* 🖥️ Desktop (MD - 3XL)/Body/Body */
    font-family: Karla;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02rem;
}

.service-description .ql-container.ql-snow .ql-editor p strong {
    /* 🖥️ Desktop (MD - 3XL)/Body/Body Bold */
    font-weight: 700;
}

.service-description .ql-container.ql-snow .ql-editor h6 {
    color: var(--Core-Text, #2C2C2C);
    /* 🖥️ Desktop (MD - 3XL)/Heading/S */
    font-family: Karla;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 1.48438rem */
    letter-spacing: -0.02375rem;
    padding-bottom: 0.25rem;
}

.service-description .ql-container.ql-bubble .ql-editor p {
    color: var(--Core-Text, #2C2C2C);
    /* 🖥️ Desktop (MD - 3XL)/Body/Body */
    font-family: Karla;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02rem;
}


.service-description .ql-container.ql-bubble .ql-editor ul li {
    list-style-type: disc;
    list-style: inside;
    padding-left: 0.6rem;
    padding-bottom: 0.75rem;

    &::before {
        content: ''
    }
}

.service-description .ql-container.ql-snow .ql-editor ul li {
    list-style-type: disc;
    list-style: inside;
    padding-left: 0.75rem;
    padding-bottom: 0.75rem;

    &::before {
        content: ''
    }
}

.service-description .ql-container.ql-bubble .ql-editor ul {
    padding-left: 0;
    color: var(--Core-Text, #2C2C2C);
    /* 🖥️ Desktop (MD - 3XL)/Body/Body */
    font-family: Karla;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02rem;
}

.service-description .ql-container.ql-snow .ql-editor ul {
    padding-left: 0;
    color: var(--Core-Text, #2C2C2C);
    /* 🖥️ Desktop (MD - 3XL)/Body/Body */
    font-family: Karla;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02rem;
}

.service-description .ql-container.ql-bubble .ql-editor ol {
    padding-left: 0;
    color: var(--Core-Text, #2C2C2C);
    /* 🖥️ Desktop (MD - 3XL)/Body/Body */
    font-family: Karla;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02rem;
}
.service-description .ql-container.ql-snow .ql-editor ol {
    padding-left: 0;
    color: var(--Core-Text, #2C2C2C);
    /* 🖥️ Desktop (MD - 3XL)/Body/Body */
    font-family: Karla;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.02rem;
}

.service-description .ql-container.ql-snow .ql-editor li {
    padding-bottom: 0.75rem;
}

.service-description .ql-container.ql-bubble .ql-editor li {
    padding-bottom: 0.75rem;
}

.service-description .ql-container.ql-bubble .ql-editor p {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}
.service-description .ql-container.ql-snow .ql-editor p {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.service-description .ql-container.ql-bubble .ql-editor p strong {
    /* 🖥️ Desktop (MD - 3XL)/Body/Body Bold */
    font-weight: 700;
}

.service-description .ql-container.ql-bubble .ql-editor h6 {
    color: var(--Core-Text, #2C2C2C);
    /* 🖥️ Desktop (MD - 3XL)/Heading/S */
    font-family: Karla;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 1.48438rem */
    letter-spacing: -0.02375rem;
    padding-bottom: 0.25rem;
}

.doctify-container a {
    padding: 0px;
}

.keep-scrolling {
    height: 400px;
    overflow-y: scroll;
    /* Add the ability to scroll the y axis */
}

/* Hide the scrollbar for Chrome, Safari and Opera */
.keep-scrolling::-webkit-scrollbar {
    display: none;
}

/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
.keep-scrolling {
    -ms-overflow-style: none;
    /* Internet Explorer and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.carousel-slider {
    overflow: visible !important;
}

.carousel-root {
    width: 100%;
}


.crossed {
    position: relative;
    display: inline-block;
    width: fit-content;
    height: fit-content;
}

.crossed::before, .cross::after {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
}

.crossed::before {
    border-bottom: 2px solid #E35349;
    -webkit-transform: skewY(-20deg);
    transform: skewY(-20deg);
}

/* Hide CookieYes floating UI */
.cky-btn-revisit-wrapper {
    display: none !important;
}

/* Use this for testing hotjar suppression, no personally identifiable data should be visible when enabled */
/* .data-hj-suppress {
    visibility: hidden;
} */

.search-match {
    color: red;
}